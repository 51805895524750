import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSession } from "react-session-persist/lib";
import * as queryString from "query-string";

import { notify } from "reapop";
import { PrimaryButton } from "../components/Buttons/PrimaryButton";
import { getCurrency, getCurrencySymbol } from "../services/currency";

import styles from "../styles/pages/cart.module.scss";
import { CartBillingDataComponent } from "../components/Cart/CartBillingData";
import { GetLocalizedText } from "../services/localizedStrings";
import { gkb } from "../services/gkb";
import { PaymentForm } from "../components/PMSelector/PaymentForm";
import { DispatchSetLoading } from "../store/ui/actions";
import { getCookie } from "../services/cookies";
import stripeLogo from "../assets/img/stripe-ssl2.png";
import { StoreState } from "../store";
import ReactGA from "react-ga";
import { useTheme } from "../store/ui/selector";

function getPrice(product: any, period: string, currency: string): number {
  if (product.Sku === "card:subordinate") {
    return 0;
  }

  for (let i = 0; i < product.Prices.length; i++) {
    if (
      product.Prices[i].Interval === period &&
      product.Prices[i].Currency === currency
    ) {
      return product.Prices[i].Amount / 100;
    }
  }

  return 0;
}

const getSubordinatePrices = (currency: string) => {
  return 35;
};

function calculatePrice(item: any, product: any, currency: string): number {
  const basePrice = getPrice(product, item.period, currency);

  if (item.type === "card:professional") {
    return basePrice * item.quantity;
  }

  let subs = item.quantity;
  if (item.type === "card:corporate" || item.type === "card:fidelium") {
    subs = item.quantity - 1;
  }

  const subPrices = getSubordinatePrices(currency);
  let subPrice = subPrices;

  return basePrice + subPrice * subs;
}

function calculateTotalPrices(
  cartItems: any,
  products: any,
  currency: any
): { totalYear: any; totalMonth: any } {
  let totalYear = 0;
  let totalMonth = 0;

  for (let i = 0; i < cartItems.length; i++) {
    const item = cartItems[i];
    const p = products.filter((pr: any) => pr.ID === item.product_id);
    let product;
    if (p && p.length > 0) {
      [product] = p;
      if (item.period === "year") {
        totalYear += calculatePrice(item, product, currency);
      } else {
        totalMonth += calculatePrice(item, product, currency);
      }
    }
  }

  return { totalYear, totalMonth };
}

export default function CartPage() {
  const router = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const crs = useSelector((state: StoreState) => state.products.countries);
  const [coupon, setCoupon] = useState<any>(null);
  const [applyingCoupon, setApplyingCoupon] = useState(0);
  const [showCoupon, setShowCoupon] = useState(false);
  const [products, setProducts] = useState([]);
  const [freeTrialDays, setFreeTrialDays] = useState(0);
  const { authenticated, session, user } = useSession();
  const { search } = useLocation();
  const [currency, setCurrency] = useState("usd");
  const [countries, setCountries] = useState(crs);
  const [symbol, setSymbol] = useState({ s: "$", p: "left" });
  const { theme } = useTheme();

  const reseller = getCookie("gkb-reseller");

  // Create our number formatter.
  const formatter = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: currency.toUpperCase(),
    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  useEffect(() => {
    return () => {
      dispatch({
        type: "@CART/SET_DATA",
        payload: [],
      });
    };
  }, []);

  useEffect(() => {
    const dc = getCurrency(countries);
    setCurrency(dc);
  }, [countries]);

  useEffect(() => {
    const symbol = getCurrencySymbol(currency);
    setSymbol(symbol);
  }, [currency]);

  const [billingData, setBillingData] = useState<any>(
    (user as any)?.BillingData
  );
  let tempCoupon = "";

  const cartItems = useSelector((state: any) => state.cart.items || []);

  const [showFares, setShowFares] = useState("");

  useEffect(() => {
    for (let i = 0; i < cartItems.length; i++) {
      if (cartItems[i].coupon && cartItems[i].coupon !== "") {
        setCoupon(cartItems[i].coupon);
        break;
      }
    }
  }, [cartItems]);

  useEffect(() => {
    const parsed = queryString.parse(search);
    const addToCart = (parsed["add-to-cart"] as string) || "";
    const company = (parsed["resource"] as string) || "";

    if ((user as any)?.ID) {
      if (addToCart && products.length > 0) {
        const [productSku, period] = addToCart.split("-");

        if (productSku) {
          const selectedProducts = products.filter(
            (p: any) => p.Sku === productSku
          );

          if (selectedProducts.length > 0) {
            const product = selectedProducts[0];
            if (product.Sku === "reseller" && (user as any)?.ResellerID) {
              router.push("/my-account/reseller");
              return;
            }

            setFreeTrialDays(product.FreeTrialDays);
            const trial = product.FreeTrialDays;
            ReactGA.event({
              category: "cart",
              action: `Item added to cart: ${product.Sku}`,
            });
            dispatch({
              type: "@CART/ADD_ITEM",
              payload: {
                product_id: product.ID,
                product,
                period,
                company,
                trial,
                user: (user as any)?.ID ?? 0,
              },
            });
            router.replace("/cart");
          }
        }
      } else {
        const aux = localStorage.getItem("gk-cart");
        if (aux) {
          const cart = JSON.parse(aux);
          if (cart?.items?.length > 0) {
            dispatch({
              type: "@CART/ADD_ITEM",
              payload: {
                ...cart.items[0],
              },
            });
          }
        }
      }
    }
  }, [session, products]);

  useEffect(() => {
    if (cartItems && cartItems[0] && cartItems[0].product.FreeTrialDays > 0) {
      setFreeTrialDays(cartItems[0].product.FreeTrialDays);
    }
    DispatchSetLoading(dispatch, true);
    gkb
      .ListApiResource(
        {
          name: "products",
          filter: "",
        },
        { sort: "show_order.asc" }
      )
      .then((response: any) => {
        if (response && response.Data) {
          setProducts(response.Data);
        }
      })
      .catch(() => {})
      .finally(() => {
        DispatchSetLoading(dispatch, false);
      });
  }, []);

  const renderCartItem = (item) => {
    let cartProduct;
    const cartProducts = products.filter((p: any) => p.ID === item.product_id);
    if (cartProducts.length > 0) {
      [cartProduct] = cartProducts;
    }

    if (!cartProduct) {
      return null;
    }

    let price = "products.pricing.per-year";
    // let amount = getPrice(cartProduct, "year");
    if (item.type === "professional" && item.period === "month") {
      price = "pricing.per-month";
      //   amount = getPrice(cartProduct, "month");
    }

    let subs = item.quantity - 1;

    if (cartProduct.Sku === "card:subordinate") {
      subs++;
    }

    let subPrices = getSubordinatePrices(currency);
    let subPrice = subPrices;

    item.product = { ...cartProduct };

    const showPrice = (price: number) => {
      if (symbol.p === "right") {
        return `${price} ${symbol.s}`;
      } else {
        return `${symbol.s} ${price}`;
      }
    };

    return (
      <li key={item.product_id} className="border-b">
        <div className="p-2 flex flex-col md:flex-row align-center justify-between">
          <div className="rounded bg-white border hidden md:block">
            <img
              alt="product-logo"
              src={item.product.ImageUrl}
              style={{ height: 150, width: 200 }}
            />
          </div>
          <div className="text-left flex-1">
            <div
              className="text-green-800 text-2xl px-10"
              style={{ color: theme?.Content?.styles?.primaryColor }}
            >
              {t(item.product.Name)}
            </div>
            <div className="px-10 my-2">
              {GetLocalizedText(item.product.Description, i18n.language)}
            </div>

            {/*{item.type !== "reseller" && (*/}
            {/*  <div className="px-10 mt-6 font-bold">*/}
            {/*    {item.type === "card:professional" ||*/}
            {/*    item.type === "card:subordinate"*/}
            {/*      ? t("cart.quantity")*/}
            {/*      : t("cart.subs")}*/}
            {/*    {(item.type === "card:corporate" ||*/}
            {/*      item.type === "card:fidelium" ||*/}
            {/*      item.type === "card:subordinate") && (*/}
            {/*      <span*/}
            {/*        className={`${styles.question} hover:opacity-60`}*/}
            {/*        onMouseEnter={() => {*/}
            {/*          setShowFares(item.id);*/}
            {/*        }}*/}
            {/*        onMouseLeave={() => {*/}
            {/*          setShowFares("");*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        <i className="fal fa-question-circle ml-2 mt" />*/}
            {/*      </span>*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*)}*/}
            {(item.type === "card:corporate" ||
              item.type === "card:fidelium" ||
              item.type === "card:subordinate") &&
              showFares === item.id && (
                <div
                  className={`${styles.info} bg-black text-white absolute p-2 rounded shadow-xl`}
                >
                  <ul>
                    <li className="flex justify-between">
                      <span className="mr-4">{t("cart.up-10-cards")}:</span>
                      <span>
                        {showPrice(subPrices[0])}{" "}
                        {t("products.pricing.per-year")}
                      </span>
                    </li>
                    <li className="flex justify-between">
                      <span className="mr-4">
                        {t("cart.from-11-50-cards")}:
                      </span>
                      <span>
                        {showPrice(subPrices[1])}{" "}
                        {t("products.pricing.per-year")}
                      </span>
                    </li>
                    <li className="flex justify-between">
                      <span className="mr-4">
                        {t("cart.from-51-100-cards")}:
                      </span>
                      <span>
                        {showPrice(subPrices[2])}{" "}
                        {t("products.pricing.per-year")}
                      </span>
                    </li>
                    <li className="flex justify-between">
                      <span className="mr-4">{t("cart.more-100-cards")}:</span>
                      <span>
                        {showPrice(subPrices[3])}{" "}
                        {t("products.pricing.per-year")}
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            {(item.type === "card:corporate" ||
              item.type === "card:fidelium" ||
              item.type === "card:subordinate") && (
              <div className="px-10 flex text-xs mb-2 italic">{`${t(
                "additional_price"
              )} ${formatter.format(subPrice)}`}</div>
            )}
            <div className="flex px-10">
              {(item.type === "card:corporate" ||
                item.type === "card:fidelium" ||
                item.type === "card:subordinate") && (
                <div className="mr-1 my-0">
                  <PrimaryButton
                    disabled={item.quantity <= 10}
                    onClick={() => {
                      dispatch({
                        type: "@CART/DECREMENT_QUANTITY",
                        payload: {
                          id: item.id,
                          quantity: 10,
                          user: (user as any)?.ID ?? 0,
                        },
                      });
                    }}
                  >
                    -10
                  </PrimaryButton>
                </div>
              )}
              {item.type !== "reseller" && (
                <div className="mr-1 my-0">
                  <PrimaryButton
                    disabled={item.quantity === 1}
                    onClick={() => {
                      dispatch({
                        type: "@CART/DECREMENT_QUANTITY",
                        payload: {
                          id: item.id,
                          quantity: 1,
                          user: (user as any)?.ID ?? 0,
                        },
                      });
                    }}
                  >
                    -
                  </PrimaryButton>
                </div>
              )}
              {item.type !== "reseller" && (
                <div className="px-3 py-2 mx-2 text-xl">
                  {item.type === "card:professional" ||
                  item.type === "card:subordinate"
                    ? item.quantity
                    : item.quantity - 1}
                </div>
              )}
              {item.type !== "reseller" && (
                <div className="mr-1 my-0">
                  <PrimaryButton
                    onClick={() => {
                      dispatch({
                        type: "@CART/INCREMENT_QUANTITY",
                        payload: {
                          id: item.id,
                          quantity: 1,
                          user: (user as any)?.ID ?? 0,
                        },
                      });
                    }}
                  >
                    +
                  </PrimaryButton>
                </div>
              )}
              {(item.type === "card:corporate" ||
                item.type === "card:fidelium" ||
                item.type === "card:subordinate") && (
                <div className="mr-1 my-0">
                  <PrimaryButton
                    onClick={() => {
                      dispatch({
                        type: "@CART/INCREMENT_QUANTITY",
                        payload: {
                          id: item.id,
                          quantity: 10,
                          user: (user as any)?.ID ?? 0,
                        },
                      });
                    }}
                  >
                    +10
                  </PrimaryButton>
                </div>
              )}
            </div>
          </div>
          <div className="flex align-center flex-col justify-center items-center">
            <div
              className="text-green-800 text-xl flex-1 md:flex md:items-end mt-8 md:mt-0  text-right w-full"
              style={{ color: theme?.Content?.styles?.primaryColor }}
            >
              {formatter.format(calculatePrice(item, cartProduct, currency))}
              {t(price)}
            </div>
          </div>
        </div>
      </li>
    );
  };

  let ty = 0;
  let tm = 0;
  if (products) {
    const { totalYear, totalMonth } = calculateTotalPrices(
      cartItems,
      products,
      currency
    );
    ty = totalYear;
    tm = totalMonth;
  }

  const couponIsValid = (cp: any) => {
    if (!cp || !cp.valid) {
      return false;
    }

    if (!cp.applies_to) {
      return true;
    }

    if (cp.applies_to.products.includes(cartItems[0].product_id)) {
      return true;
    }
    return false;
  };

  const applyCoupon = async () => {
    try {
      setApplyingCoupon(1);

      const inp: any = document.getElementById("coupon");
      tempCoupon = inp?.value ?? "";

      const code = await gkb.GetApiResource("coupons", tempCoupon);
      const couponToApply = code?.coupon;

      if (couponToApply?.metadata?.type === "Stripe") {
        const maxRedemptions = couponToApply.max_redemptions;
        const timesRedeemed = couponToApply.times_redeemed;

        const totalItemsInCart = cartItems.reduce(
          (total, item) => total + item.quantity,
          0
        );
        if (totalItemsInCart > maxRedemptions - timesRedeemed) {
          // Mostrar un mensaje de error al usuario
          console.error(
            "La cantidad de items en el carrito excede el número máximo de canjeos para este cupón"
          );
          setCoupon(couponToApply);
          setApplyingCoupon(3);
          return;
        }
      }

      if (couponIsValid(couponToApply)) {
        setCoupon({
          ...couponToApply,
          code: code.code,
          promotionCode: code.id,
          type: couponToApply.metadata.type,
        });
        dispatch({
          type: "@CART/APPLY_COUPON",
          payload: {
            coupon: {
              ...couponToApply,
              code: code.code,
              promotionCode: code.id,
              type: couponToApply.metadata.type,
            },
            user: (user as any)?.ID ?? 0,
          },
        });
        setApplyingCoupon(0);
      } else {
        setApplyingCoupon(2);
      }
    } catch (err) {
      setApplyingCoupon(2);
    }
  };

  function calculatePricesOnlyCommission(
    cartItems: any,
    products: any
  ): { totalYear: any; totalMonth: any } {
    let totalYear = 0;
    let totalMonth = 0;

    for (let i = 0; i < cartItems.length; i++) {
      const item = cartItems[i];
      const p = products.filter((pr: any) => pr.ID === item.product_id);
      let product;
      if (p && p.length > 0) {
        [product] = p;
        if (item.period === "year") {
          totalYear += 15 * item.quantity;
        }
      }
    }

    return { totalYear, totalMonth };
  }

  const removeCoupon = () => {
    setCoupon(null);
    dispatch({
      type: "@CART/APPLY_COUPON",
      payload: { coupon: null, user: (user as any)?.ID ?? 0 },
    });
    setApplyingCoupon(0);
  };

  let payNow = ty + tm;
  let discount = 0;
  if (coupon) {
    if (coupon.type === "OnlyCommission") {
      const { totalYear, totalMonth } = calculatePricesOnlyCommission(
        cartItems,
        products
      );
      payNow = totalYear;
      ty = totalYear;
      tm = 0;
    } else if (
      coupon.metadata?.type === "Stripe" &&
      cartItems.length > 0 &&
      cartItems[0].quantity > coupon.times_redeemed - coupon.max_redemptions
    ) {
      discount = 0;
    } else {
      discount = (payNow * coupon?.percent_off) / 100;
      payNow -= discount;

      if (coupon.duration === "forever") {
        ty -= (ty * coupon?.percent_off) / 100;
        tm -= (tm * coupon?.percent_off) / 100;
      }
    }
  }

  const taxes = payNow * 0.21;

  const handleBillingDataChange = (bd: any) => {
    setBillingData(bd);
  };

  if (!authenticated) {
    router.replace("/login");
    return null;
  }

  const handleCheckout = (result: any) => {
    if (!result.error) {
      dispatch({ type: "@CART/SET_DATA", payload: [] });
      router.push(`/success`, {
        subscription: result.entity.ID,
      });
    } else {
      dispatch(notify(result.error.message, "error"));
    }
  };
  const totalCartItems = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  return (
    <>
      <section className="px-4 text-center pt-5 md:pb-40 bg-gray-100">
        <div className="w-full max-w-6xl mx-auto">
          {cartItems.length > 0 && (
            <>
              <ul className="mt-10 mb-10">
                {cartItems.map((i) => renderCartItem(i))}
                {reseller && (
                  <div className="text-right text-sm italic">
                    <p>Distribuidor #{reseller}</p>
                  </div>
                )}
              </ul>
              <div className="p-2 flex items-start justify-between flex-col md:flex-row">
                <div className="w-full md:w-1/2 flex flex-col bg-gray-200 p-2 py-4 rounded-xl">
                  <h2
                    className="text-green-800 text-2xl text-left border-b border-gray-600"
                    style={{
                      color: theme?.Content?.styles?.primaryColor,
                      borderBottomColor: theme?.Content?.styles?.primaryColor,
                    }}
                  >{`${t("my-account.profile.billing-information")} `}</h2>
                  {user && (
                    <CartBillingDataComponent
                      profile={user as any}
                      handleChange={handleBillingDataChange}
                    />
                  )}
                </div>
                <div
                  className={
                    "flex flex-col w-full md:w-1/2 items-center mt-8 md:mt-0"
                  }
                >
                  <div className="w-full bg-gray-200 p-2 rounded-xl py-4 md:ml-10">
                    <h2
                      className="text-green-800 text-2xl text-left border-b border-gray-600"
                      style={{
                        color: theme?.Content?.styles?.primaryColor,
                        borderBottomColor: theme?.Content?.styles?.primaryColor,
                      }}
                    >{`${t("cart.total_price")} `}</h2>
                    <div className="w-full px-2 md:px-10">
                      <h2 className="text-2xl font-bold text-right mt-6 ">
                        {`${t("cart.pay_now")} `}
                        <span
                          className={`text-green-600 font-bold ${
                            freeTrialDays > 0 ? "line-through" : ""
                          }`}
                          style={{
                            color: theme?.Content?.styles?.primaryColor,
                          }}
                        >
                          {formatter.format(payNow)}
                        </span>
                      </h2>
                      <h2 className="text font-bold text-right mt-1 mb-6 ">
                        {`${t("cart.taxes")} `}
                        <span
                          className={`text-green-600 font-bold ${
                            freeTrialDays > 0 ? "line-through" : ""
                          }`}
                          style={{
                            color: theme?.Content?.styles?.primaryColor,
                          }}
                        >
                          {formatter.format(taxes)}
                        </span>
                      </h2>
                      {freeTrialDays > 0 && (
                        <h2
                          className="text font-bold text-right mt-1 mb-6 text-2xl"
                          style={{ color: "#FF6B0D" }}
                        >
                          {`${freeTrialDays} ${t("cart.free-days")}`}
                        </h2>
                      )}
                      <h2 className="text-2xl font-bold text-right mt-1 mb-6 border-t border-black">
                        {`${t("cart.total")} `}
                        <span
                          className={`text-green-600 font-bold ${
                            freeTrialDays > 0 ? "line-through" : ""
                          }`}
                          style={{
                            color: theme?.Content?.styles?.primaryColor,
                          }}
                        >
                          {formatter.format(payNow + taxes)}
                        </span>
                      </h2>
                      <h2 className="text-xl text-right font-bold">{`${t(
                        "later"
                      )}: `}</h2>
                      {ty > 0 && (
                        <>
                          <h2 className="text-xl text-right">
                            {formatter.format(ty)}{" "}
                            {t("products.pricing.per-year")}
                          </h2>
                          <h2 className="text text-right mt-1 mb-6">
                            <span className="">
                              +{formatter.format(ty * 0.21)}
                            </span>
                            {` ${t("cart.taxes.per-year")} `}
                          </h2>
                        </>
                      )}
                      {tm > 0 && (
                        <>
                          <h2 className="text-xl text-right">
                            {formatter.format(tm)}
                            {t("products.pricing.per-month")}
                          </h2>
                          <h2 className="text text-right mt-1 mb-6">
                            <span className="">
                              +{formatter.format(tm * 0.21)}
                            </span>
                            {`${t("cart.taxes.per-month")} `}
                          </h2>
                        </>
                      )}
                      <div
                        className={` ${
                          coupon && applyingCoupon < 2
                            ? "bg-green-200 "
                            : "bg-gray-200 "
                        }  bg-green-200 p-2 rounded-xl  mt-10 flex items-center flex-wrap`}
                        style={{
                          backgroundColor:
                            coupon && applyingCoupon < 2
                              ? theme?.Content?.styles?.secondaryColor
                              : "",
                        }}
                      >
                        {!coupon && showCoupon && (
                          <div className="flex">
                            <input
                              id="coupon"
                              type="text"
                              className="mr-4 flex-1 border-0 px-3 py-3 text-md placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 -ml-2"
                              placeholder={t("cart.coupon_code")}
                              onChange={() => {
                                setApplyingCoupon(0);
                              }}
                            />
                            <PrimaryButton
                              className="h-10"
                              onClick={applyCoupon}
                              loading={applyingCoupon === 1}
                            >
                              {t("cart.apply")}
                            </PrimaryButton>
                          </div>
                        )}
                        {applyingCoupon === 0 && coupon && (
                          <>
                            <div className="flex-1 items-left">
                              <div className="mr-4 font-bold">
                                <p>{`${t("cart.coupon_applied")}: ${
                                  coupon.code
                                }`}</p>
                              </div>
                              {coupon.type === "OnlyCommission" && (
                                <div className="mr-4 ">
                                  <p>
                                    {`Cupón de distribuidor. Solo se cobrará la comisión del marketplace`}
                                  </p>
                                </div>
                              )}
                              {coupon.type === "Reseller" && (
                                <div className="mr-4 ">
                                  <p>{`Cupón de distribuidor.`}</p>
                                </div>
                              )}
                              {coupon.type !== "OnlyCommission" &&
                                coupon.type !== "Reseller" && (
                                  <div className="mr-4 ">
                                    <p>
                                      {`${coupon.name}`} -{" "}
                                      {`${coupon.percent_off}% ${
                                        coupon.duration === "forever"
                                          ? t("cart.forever")
                                          : t("cart.first-payment")
                                      }`}
                                    </p>
                                  </div>
                                )}
                              {coupon.type === "Stripe" &&
                                coupon.max_redemptions > 0 && (
                                  <div className="mr-4 ">
                                    <p>
                                      {`${t("cart.remain-uses")}: ${
                                        coupon.max_redemptions -
                                        (coupon.times_redeemed || 0)
                                      }`}
                                    </p>
                                  </div>
                                )}
                            </div>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          </>
                        )}
                        {applyingCoupon === 2 && (
                          <>
                            <div className="w-full p-2 bg-red-300 mt-4 rounded flex justify-between">
                              <p>{t("cart.coupon-not-valid")}</p>
                              <a className="mr-4" href="#" onClick={removeCoupon}>
                                <i className="fas fa-trash text-red-500 hover:text-red-400 text" />
                              </a>
                            </div>
                          </>
                        )}
                        {applyingCoupon === 3 && coupon && (
                          <div className="w-full p-2 bg-red-300 mt-4 rounded flex justify-between">
                            <p>{t("cart.coupon-max-redeems")}</p>
                            <p>{`${t(
                              totalCartItems === 1
                                ? "cart.coupon-text-1a"
                                : "cart.coupon-text-1b"
                            )} ${cartItems.reduce(
                              (total, item) => total + item.quantity,
                              0
                            )} ${t("cart.coupon-text-2")} ${
                              coupon?.max_redemptions -
                              (coupon?.times_redeemed || 0)
                            } ${t("cart.coupon-text-3")}.`}</p>

                            <a className="mr-4" href="#" onClick={removeCoupon}>
                              <i className="fas fa-trash text-red-500 hover:text-red-400 text" />
                            </a>
                          </div>
                        )}
                      </div>
                      <div className="flex py-2 pt-4 mb-4 mt-4 flex-col">
                        <div className="mb-2">
                        <PaymentForm
                            onCheckout={handleCheckout}
                            user={user}
                            disabled={false}
                            coupon={coupon}
                            billingData={billingData}
                            cartItems={cartItems}
                            currency={currency}
                          />
                          {!showCoupon && (
                            <div className="w-full text-right ">
                              <span
                                className="text-sm mt-4 italic cursor-pointer hover:underline text-green-600"
                                onClick={() => setShowCoupon(true)}
                                style={{
                                  color: theme?.Content?.styles?.primaryColor,
                                }}
                              >
                                {t("cart.have_coupon")}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"mt-10"}>
                    <img src={stripeLogo} width={300} />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}
