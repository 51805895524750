import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useRef, useState } from "react";
import { PaymentButton } from "../Buttons/PaymentButton";
import { gkb } from "../../services/gkb";
import { useTranslation } from "react-i18next";
import { notify } from "reapop";
import { useDispatch } from "react-redux";

export const PaymentForm = ({
  disabled,
  billingData,
  cartItems,
  user,
  onCheckout,
  coupon,
  currency,
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const formRef = useRef<any>(null);
  const [saving, setSaving] = useState(false);
  const [valid, isValid] = useState(false);
  const [freeTrial, setFreeTrial] = useState(0);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // const [subscription, setSubscription] = useState<any>(null);

  useEffect(() => {
    if (cartItems.length > 0 && cartItems[0].trial > 0) {
      setFreeTrial(cartItems[0].trial);
    }
  }, [cartItems]);

  const handleOnChange = (event) => {
    if (event.complete) {
      isValid(true);
    } else {
      isValid(false);
    }
  };

  const handleCheckout = async (paymentMethod) => {
    let subs;
    let couponToApply = coupon;

    if(couponToApply && couponToApply.metadata?.type === "Stripe" && cartItems[0].quantity > coupon.times_redeemed - coupon.max_redemptions) {
      couponToApply = null;
    }

    if (cartItems.length > 0) {
      try {
        let company = 0;
        if (cartItems[0].company) {
          company = parseInt(cartItems[0].company, 10);
        }

        const intn = {
          quantity: cartItems[0].quantity,
          period: cartItems[0].period,
          product_id: cartItems[0].product_id,
          type: cartItems[0].type,
          trial: cartItems[0].trial,
          productStripeID: cartItems[0].product.StripeID,
          coupon: couponToApply?.promotionCode ?? "",
          couponCode: couponToApply?.metadata?.code ?? "",
          couponType: couponToApply?.metadata?.type ?? "",
          company,
          priceStripeID: "",
          paymentMethod,
          billingData,
        };

        for (let i = 0; i < cartItems[0].product.Prices.length; i++) {
          const p = cartItems[0].product.Prices[i];
          if (p.Interval === cartItems[0].period && p.Currency === currency) {
            intn.priceStripeID = p.StripeID;
          }
        }

        subs = await gkb.CreateIntent(intn);

        if (subs.error) {
          const msg = JSON.parse(subs.error.details);
          if (
            msg.message?.startsWith(
              "You cannot combine currencies on a single customer."
            )
          ) {
            dispatch(notify(t("payment.multiple-currencies-error"), "error"));
          } else {
            dispatch(notify(t("payment.general-error"), "error"));
          }
          return;
        }

        if (paymentMethod && subs.sub.latest_invoice.payment_intent) {
          const confirmation: any = await stripe?.confirmCardPayment(
            subs.sub.latest_invoice.payment_intent.client_secret,
            {
              payment_method: paymentMethod.id,
            }
          );
          await gkb.ConfirmIntent(subs.sub.id, confirmation);

          if (confirmation.error) {
            onCheckout(confirmation);
            return;
          }
        } else {
          await gkb.ConfirmIntent(subs.sub.id, null);
        }

        if (billingData.saveAsDefault) {
          await gkb.UpdateApiResource({
            name: "accounts",
            id: user?.ID,
            body: {
              BillingData: {
                Name: billingData.name,
                Address: billingData.address,
                Vat: billingData.cif,
                City: billingData.city,
                Country: billingData.country,
                Email: billingData.email,
                Phone: billingData.phone,
                State: billingData.state,
                ZipCode: billingData.zipCode,
              },
            },
          });
        }
        onCheckout(subs);
      } catch (err) {
        console.log(err)
        dispatch(notify(err, "error"));
      }
    }
  };

  const handlePay = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setSaving(true);
    if (freeTrial === 0 && (disabled || !valid)) {
      return;
    }

    if (freeTrial === 0) {
      const cardElement = elements?.getElement(CardElement);

      if (!stripe || !cardElement) {
        setSaving(false);
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          phone: billingData.phone !== "" ? billingData.phone : undefined,
          email: billingData.email !== "" ? billingData.email : undefined,
          name: billingData.name !== "" ? billingData.name : undefined,
          address: {
            city: billingData.city !== "" ? billingData.city : undefined,
            country:
              billingData.country !== "" ? billingData.country : undefined,
            state: billingData.state !== "" ? billingData.state : undefined,
            postal_code:
              billingData.zipCode !== "" ? billingData.zipCode : undefined,
            line1: billingData.address !== "" ? billingData.address : undefined,
          },
        },
        metadata: {
          vat: billingData.Cif,
        },
      });

      if (!error) {
        await handleCheckout(paymentMethod);
      }
    } else {
      await handleCheckout(null);
    }

    setSaving(false);
  };

  return (
    <div>
      <form ref={formRef} onSubmit={handlePay} action={"#"}>
        {freeTrial === 0 && (
          <div className="bg-white rounded mb-4">
            <CardElement
              id="stripe-card-element"
              className={`${
                !valid ? "border-red-600" : "border-green-600"
              } border px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear  `}
              options={{
                hidePostalCode: true,
              }}
              onChange={handleOnChange}
            />
          </div>
        )}
        <PaymentButton
          disabled={freeTrial === 0 && (disabled || !valid)}
          loading={saving}
          full
          type={"submit"}
          //onClick={handlePay}
        >
          {t(freeTrial > 0 ? "cart.start-free-trial" : "cart.pay-with-card")}
        </PaymentButton>
      </form>
    </div>
  );
};
