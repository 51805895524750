// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dTFd\\+VaBcmwoVfBPDqKV6A\\=\\= li:last-of-type{border:none}", "",{"version":3,"sources":["webpack://./src/components/Subscription/SubscriptionDetails.module.scss"],"names":[],"mappings":"AAEI,6CACI,WAAA","sourcesContent":[".gktable {\n   \n    li:last-of-type {\n        border: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gktable": "dTFd+VaBcmwoVfBPDqKV6A=="
};
export default ___CSS_LOADER_EXPORT___;
