import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
//import logo from "../../assets/img/logo.jpg";
//import logoMini from "../../assets/img/logo_mini.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IndexDropdown from "./IndexDropdown";
import { useSession } from "react-session-persist/lib";
import ReactFlagsSelect from "react-flags-select";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { SecondaryButton } from "../Buttons/SecondaryButton";
import IndexDropdownMobile from "./IndexDropdownMobile";
import { getCookie, setCookie } from "../../services/cookies";
import ReactGA from "react-ga";
import { useTheme } from "../../store/ui/selector";

function setWindowTitle(pathname: string) {
      window.document.title = "EKard";
}

type GKLinkProperties = {
  text: string;
  url: string;
  style: any;
};


const GKLink = ({ text, url, style }: GKLinkProperties) => {
  return <li className="flex items-center">
    <Link to={url}>
      <span
        style={{ color: style?.primaryColor }}
        className="hover:text-green-500 text-green-700 px-3 py-4 lg:py-2 flex items-center text-lg  font-medium hover:underline">
        {text}
      </span>
    </Link>
  </li>
};

const countryToLocale: any = {
  GB: "en",
  ES: "es",
  PT: "pt",
  FR: "fr",
  DE: "de",
  NL: "nl",
};

const localeToCountry: any = {
  en: "GB",
  es: "ES",
  pt: "PT",
  fr: "FR",
  de: "DE",
  nl: "NL",
};

export function Navbar() {
  const { t, i18n } = useTranslation();
  const { theme, defaultTheme } = useTheme();
  const { authenticated, user } = useSession();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useState("");
  const [country, setCountry] = useState("");
  const router = useHistory();
  const data: any = undefined;

  useEffect(() => {
    setWindowTitle(location.pathname);
    ReactGA.pageview(location.pathname);
    setIsOpen(false);
  }, [location]);

  useEffect(() => {
    if (
      data &&
      data.shopping_cart_items &&
      data.shopping_cart_items.length > 0
    ) {
      const items = [];
      for (let index = 0; index < data.shopping_cart_items.length; index++) {
        if (data.shopping_cart_items[index].coupon) {
          const coupon: any = JSON.parse(
            data.shopping_cart_items[index].coupon
          );

          items.push({
            ...data.shopping_cart_items[index],
            // @ts-ignore
            coupon,
          });
        } else {
          // @ts-ignore
          items.push({ ...data.shopping_cart_items[index] });
        }
      }
      // dispatch({ type: '@CART/SET_DATA', payload: items });
    }
  }, [data]);

  useEffect(() => {
    let locale = getCookie("GK-LANGUAGE");
    if (!locale) {
      locale = navigator.language.split("-")[0];
    }

    if (locale) {
      setLanguage(locale);
      setCountry(localeToCountry[locale]);
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(language).then().catch();
  }, [language]);

  const handleChangeCountry = async (code: string) => {
    setCountry(code);
    setLanguage(countryToLocale[code]);
  };

  const isAdmin = (user as any)?.Roles?.includes("admin") || (user as any)?.Roles?.includes("superadmin");

  return (
    <div>
      <nav className="bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-20">
            <div className="flex items-center w-full">
              <div className="flex w-full">
                <Link to="/">
                  <img
                    className="md:hidden h-10 w-20"
                    style={theme?.Content?.logo?.styles || defaultTheme?.Content?.logo?.styles}
                    src={theme?.LogoMini || defaultTheme?.logoMini}
                    alt="EKard"
                  />
                  { theme && theme.Logo && <img
                    className="hidden md:block h-10 w-auto"
                    style={theme?.Content?.logo?.styles || defaultTheme?.Content?.logo?.styles}
                    src={theme?.Logo || defaultTheme?.logo}
                    alt="EKard"
                  />}
                </Link>
              </div>
              <div className="hidden md:block w-full">
                <div className="ml-10 flex items-baseline space-x-4  justify-center">
                  <GKLink text={t("navbar.home")} url="/" style={theme?.Content?.styles || defaultTheme?.Content?.styles} />
                  <GKLink text={t("navbar.products")} url="/products"  style={theme?.Content?.styles || defaultTheme?.Content?.styles}/>
                  {/*<GKLink text={t("navbar.resellers")} url="/resellers" />*/}
                  <GKLink text="Faqs" url="/faqs" style={theme?.Content?.styles || defaultTheme?.Content?.styles}/>
                </div>
              </div>
              <div className="hidden md:block flex-none">
                <div className="flex items-center space-x-4  justify-end">
                  {authenticated && (
                    <div className="flex items-center">
                      <IndexDropdown t={t} />
                    </div>
                  )}
                  {!authenticated && (
                    <>
                      <li className="flex items-center">
                        <PrimaryButton
                          onClick={() => {
                            router.push("/login");
                          }}
                        >
                          {t("navbar.login")}
                        </PrimaryButton>
                      </li>
                      <li className="flex items-center">
                        <SecondaryButton
                          className={"ml-2"}
                          onClick={() => {
                            router.push("/register");
                          }}
                        >
                          {t("navbar.signup")}
                        </SecondaryButton>
                      </li>
                    </>
                  )}
                  <div className={"h-20 flex items-center"}>
                    <ReactFlagsSelect
                      selectButtonClassName="menu-flag-select"
                      className="menu-flags"
                      selected={country}
                      countries={["ES", "GB", "PT", "FR", "DE", "NL"]}
                      customLabels={{
                        GB: "en",
                        ES: "es",
                        PT: "pt",
                        FR: "fr",
                        DE: "de",
                        NL: "nl",
                      }}
                      onSelect={handleChangeCountry}
                      showSelectedLabel={false}
                      showOptionLabel={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        {authenticated && (
          <div
            className={""}
            style={{
              width: "100%",
              height: 29,
              backgroundColor: theme?.Content?.styles?.navBar?.backgroundColor || "#334155",
              color: theme?.Content?.styles?.navBar?.textColor || "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 999999,
            }}
          >
            <Link to="/my-account">
              <span className="px-4 font-normal block w-full whitespace-nowrap bg-transparent hover:text-gray-400">
                <span className="">
                  <i className="fal fa-user mr-2 w-5" />
                  {t("navbar.profile")}
                </span>
              </span>
            </Link>
            <Link to="/my-account/cards">
              <span className="px-4 font-normal block w-full whitespace-nowrap bg-transparent hover:text-gray-400">
                <span className="">
                  <i className="fal fa-id-card mr-2 w-5" />
                  {t("navbar.cards")}
                </span>
              </span>
            </Link>
            <Link to="/my-account/subscriptions">
              <span className="px-4 font-normal block w-full whitespace-nowrap bg-transparent hover:text-gray-400">
                <span className="">
                  <i className="fal fa-file-contract mr-2 w-5" />
                  {t("navbar.subscriptions")}
                </span>
              </span>
            </Link>
            <Link to="/my-account/payments">
              <span className="px-4 font-normal block w-full whitespace-nowrap bg-transparent hover:text-gray-400">
                <span className="">
                  <i className="fal fa-credit-card mr-2 w-5" />
                  {t("navbar.payments")}
                </span>
              </span>
            </Link>
            {isAdmin && <Link to="/gkbo">
              <span className="px-4 font-normal block w-full whitespace-nowrap bg-transparent hover:text-gray-400">
                <span className="">
                  <i className="fal fa-tachometer mr-2 w-5" />
                  {t("navbar.dashboard")}
                </span>
              </span>
            </Link> }

          </div>
        )}

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="md:hidden h-screen" id="mobile-menu">
              <div
                ref={ref}
                className="px-2 pt-2 pb-3 space-y-1 sm:px-3 flex flex-col"
              >
                <div className="w-full flex justify-center">
                  <GKLink text={t("navbar.home")} url="/products"  style={theme?.Content?.styles || defaultTheme?.Content?.styles}/>
                </div>
                <div className="w-full flex justify-center">
                  <GKLink text={t("navbar.products")} url="/products"  style={theme?.Content?.styles || defaultTheme?.Content?.styles}/>
                </div>
                <div className="w-full flex justify-center">
                  <GKLink text={t("navbar.resellers")} url="/resellers"  style={theme?.Content?.styles || defaultTheme?.Content?.styles}/>
                </div>
                <div className="w-full flex justify-center">
                  <GKLink text="FAQS" url="/faqs"  style={theme?.Content?.styles || defaultTheme?.Content?.styles}/>
                </div>
              </div>
              {authenticated && (
                <div className="flex items-center justify-center">
                  <IndexDropdownMobile t={t} />
                </div>
              )}
              {!authenticated && (
                <div className="flex w-full justify-center my-4">
                  <div className="flex items-center">
                    <PrimaryButton
                      full={true}
                      onClick={() => {
                        router.push("/login");
                      }}
                    >
                      {t("navbar.login")}
                    </PrimaryButton>
                  </div>
                  <div className="flex items-center">
                    <SecondaryButton
                      full={true}
                      className={"ml-2"}
                      onClick={() => {
                        router.push("/register");
                      }}
                    >
                      {t("navbar.signup")}
                    </SecondaryButton>
                  </div>
                </div>
              )}
              <div className={"h-20 flex items-center"}>
                <ReactFlagsSelect
                  selectButtonClassName="menu-flag-select-mobile"
                  className="menu-flags-mobile"
                  selected={country}
                  countries={["ES", "GB", "PT", "FR", "DE", "NL"]}
                  customLabels={{
                    GB: "en",
                    ES: "es",
                    PT: "pt",
                    FR: "fr",
                    DE: "de",
                    NL: "nl",
                  }}
                  onSelect={handleChangeCountry}
                  showSelectedLabel={false}
                  showOptionLabel={false}
                />
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </div>
  );
}

export default Navbar;
