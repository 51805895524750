import React, { useEffect, useState } from "react";
// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import BgLoader from "../bgLoader/BgLoader";
import LoginFormComponent from "./loginForm";
// import LoginSocialComponent from './loginSocial';
import { RegisterFormComponent } from "./RegisterForm";
import { useTranslation } from "react-i18next";
import { LoginSocialComponent } from "./LoginSocial";
import ReactGA from "react-ga";
import { useTheme } from "../../store/ui/selector";

export default function LoginComponent({ setAuthInfo, selectedTab }: any) {
  const [tabSelected, selectTab] = useState(0);
  const selectedClass = "text-green-600 border-green-600 font-bold";
  const router = useHistory();
  const { t } = useTranslation();
  const { theme } = useTheme();

  useEffect(() => {
    const newTab = parseInt(selectedTab, 10);
    selectTab(newTab);
  }, [selectedTab]);

  const prepareToken = (token: any) => {
    ReactGA.set({ userId: token.account.ID });
    setAuthInfo({
      userId: token.user_id,
      profile: token.account,
    });
  };

  const handleChangeTab = (newTab: number) => {
    selectTab(newTab);
  };

  return (
    <>
      <section className="text-center pb-10 m-auto w-full md:w-6/12 bg-gray-100 border rounded shadow-lg  bg-gray-100">
        <ul className="w-full flex justify-center py-4">
          <li
            className={`text-lg cursor-pointer flex flex-1 justify-center border-b ${
              tabSelected === 0 ? selectedClass : ""
            }`}
            onClick={() => {
              router.replace("/login");
            }}
            style={{
              color: tabSelected === 0? theme?.Content?.styles?.primaryColor: "" ,
              borderColor: tabSelected === 0? theme?.Content?.styles?.primaryColor: ""
          }}
          >
            {t("login.login")}
          </li>
          <li
            className={`text-lg cursor-pointer flex flex-1 justify-center border-b ${
              tabSelected === 1 ? selectedClass : ""
            }`}
            onClick={() => {
              router.replace("/register");
            }}
            style={{
              color: tabSelected === 1? theme?.Content?.styles?.primaryColor: "" ,
              borderColor: tabSelected === 1? theme?.Content?.styles?.primaryColor: ""
            }}
          >
            {t("login.register")}
          </li>
        </ul>
        <div className="w-full justify-center flex">
          {tabSelected === 0 && (
            <LoginFormComponent
              prepareToken={prepareToken}
              onChangeTab={handleChangeTab}
            />
          )}
          {tabSelected === 1 && (
            <RegisterFormComponent onChangeTab={handleChangeTab} />
          )}
        </div>
        {/*<LoginSocialComponent prepareToken={prepareToken} />*/}
      </section>
    </>
  );
}
