// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Li2HWZAG7SCDOisGUH\\+ErA\\=\\={z-index:100;display:flex;justify-content:center;align-items:flex-start;padding-top:100px;background-color:rgba(0,0,0,.6)}", "",{"version":3,"sources":["webpack://./src/components/Modals/NotificationModal/NotificationModal.module.scss"],"names":[],"mappings":"AAAA,6BACE,WAAA,CACA,YAAA,CACA,sBAAA,CACA,sBAAA,CACA,iBAAA,CACA,+BAAA","sourcesContent":[".backdrop {\n  z-index: 100;\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  padding-top: 100px;\n  background-color: rgba(0, 0, 0, 0.6);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": "Li2HWZAG7SCDOisGUH+ErA=="
};
export default ___CSS_LOADER_EXPORT___;
