// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hhBgWFp4SYDBMbpTRc9XpA\\=\\={width:400px;margin:40px;padding:10px;border:1px solid}.ChcFhffW6cB\\+oFCqxvEogQ\\=\\={width:100%;border-top:5px solid red}", "",{"version":3,"sources":["webpack://./src/components/Sections/SectionHowItWorks.module.scss"],"names":[],"mappings":"AAAA,4BACE,WAAA,CACA,WAAA,CACA,YAAA,CACA,gBAAA,CAGF,6BACE,UAAA,CACA,wBAAA","sourcesContent":[".card {\n  width: 400px;\n  margin: 40px;\n  padding: 10px;\n  border: 1px solid;\n}\n\n.divider {\n  width: 100%;\n  border-top: 5px solid red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "hhBgWFp4SYDBMbpTRc9XpA==",
	"divider": "ChcFhffW6cB+oFCqxvEogQ=="
};
export default ___CSS_LOADER_EXPORT___;
