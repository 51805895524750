// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/img/background-1.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3suEzw5JcvQqPDHAavh1Tg\\=\\={background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover}", "",{"version":3,"sources":["webpack://./src/styles/pages/products.module.scss"],"names":[],"mappings":"AAAA,6BACE,wDAAA,CACA,2BAAA,CACA,qBAAA","sourcesContent":[".section1 {\n  background-image: url(../../assets/img/background-1.jpg);\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section1": "_3suEzw5JcvQqPDHAavh1Tg=="
};
export default ___CSS_LOADER_EXPORT___;
