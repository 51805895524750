import "./assets/fontawesome/css/all.css";

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from "./pages/HomePage";
import {
  removeSession,
  saveSession,
  saveUser,
  getAuthenticated
} from "react-session-persist/lib";
import BgLoader from "./components/bgLoader/BgLoader";
import { useDispatch, useSelector } from "react-redux";
import NotificationsSystem, {
  dismissNotification,
  bootstrapTheme,
  setUpNotifications,
  notify,
} from "reapop";
import { Elements } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import IndexNavbar from "./components/Navbars/IndexNavbar";
import Footer from "./components/Footers/Footer";
import LoginPage from "./pages/LoginPage";
import { DispatchSetLoading } from "./store/ui/actions";
import { useLoading } from "./store/ui/selector";
import ProductsPage from "./pages/ProductsPage";
import FaqsPage from "./pages/FaqsPage";
import FaqsSectionPage from "./pages/FaqSectionPage";
import CartPage from "./pages/CartPage";
import SuccessPage from "./pages/SuccessPage";
import ScrollToTop from "./components/ScrollToTop";
import MyAccountPage from "./pages/MyAccount";
import { StoreState } from "./store";
import { gkb } from "./services/gkb";
import NotVerifiedPage from "./pages/NotVerified";
import WelcomePage from "./pages/welcome";
import EditorPage from "./pages/EditorPage";
import ContactPage from "./pages/contactPage";
import Page404 from "./pages/404";
import ChangePasswordPage from "./pages/changePassword";
import ActivatePage from "./pages/activatePage";
import ReactGA from "react-ga";
import FooterMobile from "./components/Footers/FooterMobile";
import LegalPage from "./pages/LegalPage";
import CookieConsent from "./components/cookieConsent/cookieConsent";

setUpNotifications({
  defaultProps: {
    position: "top-right",
    dismissible: true,
    showDismissButton: false,
    dismissAfter: 4000,
  },
});

export const App = ({ stripe }) => {
  const dispatch = useDispatch();
  const loading = useLoading();
  const notifications = useSelector((state: StoreState) => state.notifications);
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const [lng, setLng] = useState<any>(language);

  const relogin = async () => {
    DispatchSetLoading(dispatch, true);
    try {
      const authInfo = await gkb.RefreshToken();
      if (authInfo) {
        await saveSession({ userId: authInfo.user_id });
        await saveUser({ ...authInfo.account });
      } else {
        await removeSession();
      }
    } catch(err) {
      await removeSession();
    }
    finally
    {
      DispatchSetLoading(dispatch, false);
    }
  };

  const handleShowNotification = (evt: any) => {
    const { message, type } = evt.detail;
    dispatch(notify(t(message), type));
  };

  const handleSetSpinner = (evt: any) => {
    DispatchSetLoading(dispatch, evt.detail);
  };

  const handleLogout = () => {
    removeSession().then(() => {
      // if(window.location.pathname !== "/login") {
      //   window.location.pathname = "/login";
      // }
    });
  }

  useEffect(() => {
    ReactGA.initialize("UA-166639889-2");

    const authenticated = getAuthenticated();
    if (!authenticated) {
      relogin()
        .then(() => {})
        .catch(() => {});
    }

    window.addEventListener(
      "General.showNotification",
      handleShowNotification,
      false
    );
    window.addEventListener("General.SetSpinner", handleSetSpinner);
    window.addEventListener("General.Logout", handleLogout);

    gkb
      .GetApiResource("themes", "mine")
      .then((resp: any) => {
        if (resp) {
          dispatch({ type: "@UI/SET_THEME", payload: resp });
        } else {
          gkb.Logout()
          removeSession();
        }
      })
      .catch(() => {
        removeSession();
      });

    gkb
      .ListApiResource(
        {
          name: "products",
          filter: "",
        },
        { sort: "show_order.asc" }
      )
      .then((resp: any) => {
        if (resp?.Data) {
          dispatch({ type: "@PRODUCTS/SET_DATA", payload: resp.Data });
        }
      })
      .catch(() => {});

    gkb
      .ListApiResource(
        {
          name: "countries",
          filter: "",
        },
        { pageSize: 500 }
      )
      .then((resp: any) => {
        if (resp?.Data) {
          dispatch({ type: "@PRODUCTS/SET_COUNTRIES", payload: resp.Data });
        }
      })
      .catch(() => {});

    // relogin()
    //   .then(() => {})
    //   .catch(() => {});

    return () => {
      window.removeEventListener("General.SetSpinner", handleSetSpinner);
      window.removeEventListener("General.Logout", handleLogout);

      window.removeEventListener(
        "General.showNotification",
        handleShowNotification
      );
    };
  }, []);

  useEffect(() => {
    setLng(language);
  }, [language]);

  return (
    <div>
      {loading && <BgLoader />}
      <NotificationsSystem
        notifications={notifications}
        dismissNotification={(id) => dispatch(dismissNotification(id))}
        theme={bootstrapTheme}
      />
      <Router>
        <ScrollToTop />
        <IndexNavbar />
        <Elements stripe={stripe} options={{ locale: lng }}>
          <Switch>
            <Route path="/" exact children={<HomePage />} />
            <Route path="/login" children={<LoginPage selectedTab={0} />} />
            <Route path="/register" children={<LoginPage selectedTab={1} />} />
            <Route path="/products" children={<ProductsPage />} />
            <Route path="/cart" children={<CartPage />} />
            <Route path="/faqs/:section" exact children={<FaqsSectionPage />} />
            <Route path="/faqs" children={<FaqsPage />} />
            <Route path="/success" children={<SuccessPage />} />
            <Route path="/my-account" children={<MyAccountPage />} />
            <Route path="/not-verified" children={<NotVerifiedPage />} />
            <Route path="/welcome" children={<WelcomePage />} />
            <Route path="/editor/:id" children={<EditorPage />} />
            <Route path="/contact" children={<ContactPage />} />
            <Route path="/legal/privacy" children={<LegalPage page={"privacy"}/>} />
            <Route path="/legal/conditions" children={<LegalPage page={"conditions"}/>} />
            <Route path="/legal/cookies" children={<LegalPage page={"cookies"}/>} />
            {/*<Route path="/resellers" exact children={<ResellersPage />} />*/}
            {/*<Route path="/resellers-info" children={<ResellersInfoPage />} />*/}
            {/*<Route exact path="/linkedin" component={LinkedInPopUp} />*/}
            <Route
              exact
              path="/changepassword/:code"
              component={ChangePasswordPage}
            />
            <Route exact path="/activate/:code" component={ActivatePage} />

            <Route path="" children={<Page404 />} />
          </Switch>
        </Elements>
        <Footer />
        <FooterMobile />
        <CookieConsent />
      </Router>
    </div>
  );
};
