// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/img/background-1.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SGWeH80yplw57DZzJSdprQ\\=\\={background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover;margin-top:160px}._6Dx-2ktsqDpKmEDDbXzeQw\\=\\={width:400px;margin:40px;padding:10px;border-radius:.5rem;cursor:pointer}._6Dx-2ktsqDpKmEDDbXzeQw\\=\\=:hover{background-color:#f5f5f5}.KTPvGpm72nnGVogAZh8tSg\\=\\=:hover{cursor:pointer}.EE4bucQkDGX74Y8g2ztzZw\\=\\={transform:translateX(100px)}", "",{"version":3,"sources":["webpack://./src/styles/pages/cart.module.scss"],"names":[],"mappings":"AAAA,4BACE,wDAAA,CACA,2BAAA,CACA,qBAAA,CAEA,gBAAA,CAGF,6BACE,WAAA,CACA,WAAA,CACA,YAAA,CACA,mBAAA,CACA,cAAA,CAEA,mCACE,wBAAA,CAKF,kCACE,cAAA,CAIJ,4BACE,2BAAA","sourcesContent":[".section1 {\n  background-image: url(../../assets/img/background-1.jpg);\n  background-repeat: no-repeat;\n  background-size: cover;\n\n  margin-top: 160px;\n}\n\n.card {\n  width: 400px;\n  margin: 40px;\n  padding: 10px;\n  border-radius: 0.5rem;\n  cursor: pointer;\n\n  &:hover {\n    background-color: #f5f5f5;\n  }\n}\n\n.question {\n  &:hover {\n    cursor: pointer;\n  }\n}\n\n.info {\n  transform: translateX(100px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section1": "SGWeH80yplw57DZzJSdprQ==",
	"card": "_6Dx-2ktsqDpKmEDDbXzeQw==",
	"question": "KTPvGpm72nnGVogAZh8tSg==",
	"info": "EE4bucQkDGX74Y8g2ztzZw=="
};
export default ___CSS_LOADER_EXPORT___;
