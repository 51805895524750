// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QJsHaLTsmOz9wxWIyDGUAw\\=\\={--tw-bg-opacity: 1;background-color:rgba(4, 120, 87, var(--tw-bg-opacity))}.QJsHaLTsmOz9wxWIyDGUAw\\=\\= span{color:#f5f5f5}.QJsHaLTsmOz9wxWIyDGUAw\\=\\=:hover span{color:#000}.XEHA32wylurk9UOhCNRTFA\\=\\={height:-moz-fit-content !important;height:fit-content !important;min-width:256px}", "",{"version":3,"sources":["webpack://./src/components/Navbars/MyAccountNav.module.scss"],"names":[],"mappings":"AAAA,4BACI,kBAAA,CACA,uDAAA,CAEA,iCACI,aAAA,CAID,uCACC,UAAA,CAKR,4BACI,kCAAA,CAAA,6BAAA,CACA,eAAA","sourcesContent":[".selected {\n    --tw-bg-opacity: 1;\n    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));\n    \n    span {\n        color: whitesmoke; \n   }\n\n   &:hover {\n       span {\n        color: black;\n       }\n   }\n}\n\n.navWrapper {\n    height: fit-content !important;\n    min-width: 256px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selected": "QJsHaLTsmOz9wxWIyDGUAw==",
	"navWrapper": "XEHA32wylurk9UOhCNRTFA=="
};
export default ___CSS_LOADER_EXPORT___;
