// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UUV77fOBeE5ejWroi1lohA\\=\\={background-color:#a1c434;padding-top:30px;padding-bottom:30px;border-radius:.6rem;transform:translateY(-60px)}", "",{"version":3,"sources":["webpack://./src/components/Sections/SectionCTA2.module.scss"],"names":[],"mappings":"AAAA,4BACE,wBAAA,CACA,gBAAA,CACA,mBAAA,CACA,mBAAA,CACA,2BAAA","sourcesContent":[".cta2 {\n  background-color: #a1c434;\n  padding-top: 30px;\n  padding-bottom: 30px;\n  border-radius: 0.6rem;\n  transform: translateY(-60px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cta2": "UUV77fOBeE5ejWroi1lohA=="
};
export default ___CSS_LOADER_EXPORT___;
