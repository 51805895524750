import React from "react";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SecondaryButton } from "../Buttons/SecondaryButton";

export default function FooterMobile() {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <footer className="relative bg-gray-900 pt-8 pb-6 max-h-860-px md:hidden">
        <div className="flex flex-col flex-wrap max-w-7xl mx-auto">
          <div className="text-center">
            <h3 className="text-2xl font-semibold font-heading text-gray-400 my-4">
              {t("footer.contact-us")}
            </h3>
            <div>
              <SecondaryButton
                onClick={() => {
                  history.push("/contact");
                }}
              >
                {t("footer.contact-form")}
              </SecondaryButton>
            </div>
          </div>
          <div className="text-center">
            <h3 className="text-2xl font-semibold font-heading text-gray-400 my-4">
              {t("footer.links")}
            </h3>
            <ul>
              <li className="text-white my-4">
                <Link to="/" className="hover:underline">
                  <a href="#">{t("footer.home")}</a>
                </Link>
              </li>
              <li className="text-white my-4">
                <Link to="/products">
                  <a href="#" className="hover:underline">
                    {t("footer.products")}
                  </a>
                </Link>
              </li>
              <li className="text-white my-4">
                <Link to="/resellers">
                  <a href="#" className="hover:underline">
                    {t("footer.resellers")}
                  </a>
                </Link>
              </li>
              <li className="text-white my-4">
                <Link to="/faqs">
                  <a href={"#"} className="hover:underline">
                    {t("footer.faqs")}
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <footer className="relative bg-black pt-8 pb-14 mb-8  px-10 flex flex-col justify-around text-white  md:hidden">
        <div className="flex flex-1 justify-center items-center flex-col">
          <Link to="/legal/privacy" className="mt-4">
            <a href="#" className="mx-10 hover:underline text-center">
              {t("footer.privacy")}
            </a>
          </Link>
          <Link to="/legal/conditions" className="mt-4">
            <a href="#" className="mx-10 hover:underline">
              {t("footer.terms")}
            </a>
          </Link>
          <Link to="/legal/cookies" className="mt-4">
            <a href="#" className="mx-10 hover:underline">
              {t("footer.cookies")}
            </a>
          </Link>
        </div>
        <p className="text-center text-gray-400 mt-4">EKard © 2021</p>
      </footer>
    </>
  );
}
