import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table } from "../../../components/Table/Table";
import { gkb } from "../../../services/gkb";
import ReactGA from "react-ga";
import { useTheme } from "../../../store/ui/selector";

const CardsPage = () => {
  const router = useHistory();
  const { t } = useTranslation();
  const { theme } = useTheme();

  const settings = {
    columns: [
      { text: "#", field: "ID" },
      { text: t("created_at"), field: "CreatedAt", type: "date" },
      {
        text: t("cards.status"),
        width: 200,
        field: "Status",
        getTextStyle: (value) => {
          switch (value.toLowerCase()) {
            case "expired":
              return "rounded bg-yellow-200 py-1 px-3 text-xs font-bold";
            case "active":
              return "rounded bg-green-200 py-1 px-3 text-xs font-bold";
            case "trialing":
              return "rounded bg-blue-200 py-1 px-3 text-xs font-bold";
            case "past_due":
              return "rounded bg-red-400 py-1 px-3 text-xs font-bold";
            default:
              return "";
          }
        },
      },
      { text: t("type"), field: "Sku" },
      { text: t("name_alias"), field: "Name" },
      { text: "", field: "actions" },
    ],
    rowColor: (row) => {
      if (row.Status === "canceled" || row.Status === "past_due") {
        return "bg-red-100";
      } else if (row.Status === "incomplete") {
        return "bg-yellow-100";
      } else{
        return "bg-white";
      }
    },
    actions: [
      {
        text: t("cards.goto-subscription"),
        action: "goto-subscription",
        color: "text-green-600",
        icon: "fa-file-alt",
      },
      {
        text: t("cards.go"),
        action: "goto-card",
        color: "text-yellow-600",
        icon: "fa-external-link",
      },
      {
        text: t("cards.configure"),
        action: "configure-card",
        color: "text-green-600",
        icon: "fa-edit",
      },
      {
        text: t("cards.configure-company"),
        action: "configure-company",
        color: "text-green-600",
        icon: "fa-building",
      },
      {
        text: t("cards.configure-template"),
        action: "configure-template",
        color: "text-green-600",
        icon: "fa-id-card",
      },
      {
        text: t("cards.configure-subordinates"),
        action: "configure-subordinates",
        color: "text-green-600",
        icon: "fa-address-book",
      },
    ],
  };

  const fetchData = async (pageSize, page) => {
    try {
      const response = await gkb.ListApiResource(
        {
          name: "resources",
        },
        { pageSize, page }
      );

      return {
        data: response.Data.map((s) => ({
          ...s,
        })),
        totalPages: response.TotalPages,
      };
    } catch (err) {
      return {
        data: [],
        totalCount: 0,
      };
    }
    // };
  };

  const handleTableAction = async (action, row) => {
    if (action === "goto-card" && row.Name) {
      ReactGA.event({
        category: "cards",
        action: `View card ${row.ID}`,
      });
      debugger
      window.open("https://v2.gkd.es/" + row.Name, "_blank").focus();
    } else if (action === "configure-card") {
      ReactGA.event({
        category: "cards",
        action: `Edit card ${row.ID}`,
      });
      router.push(`/editor/card/${row.ID}`);
    } else if (action === "configure-company") {
      router.push(`/my-account/cards/company/${row.ID}`);
    } else if (action === "configure-template") {
      ReactGA.event({
        category: "cards",
        action: `Edit template ${row.MastercardID}`,
      });
      router.push(`/editor/template/${row.MastercardID}`);
    } else if (action === "configure-subordinates") {
      router.push(`/my-account/cards/${row.ID}/subordinates`);
    } else if (action === "goto-subscription") {
      router.push(`/my-account/subscriptions/${row.SubscriptionID}`);
    }
  };

  const handleRenderText = (value, column) => {
    if (column.field === "Sku") {
      return (
        <span className="capitalize">
          {t(value.replace(":", "-").toLowerCase())}
        </span>
      );
    }
    if (column.field === "Status") {
      return t(value);
    }
    return value;
  };

  const handleRenderAction = (action, row) => {
    if(row.Status === 'incomplete') {
      return false;
    }

    if (row.Status === "past_due" && action !== "goto-subscription") {
      return false;
    }
    if (action === "goto-subscription" && row.Sku.startsWith("sub:")) {
      return false;
    }
    if (action === "goto-card") {
      if (!row.Name) {
        return false;
      }
      return row.Sku === "card:professional";
    }
    if (action === "configure-company") {
      return row.ContentType === "company";
    }
    if (action === "configure-card") {
      return row.ContentType === "card";
    }
    if (action === "configure-template") {
      return row.ContentType === "company";
    }
    if (action === "configure-subordinates") {
      return row.ContentType === "company";
    }

    return true;
  };

  return (
    <section className="px-4 text-center pb-40 w-full">
      <Table
        settings={settings}
        fetchData={fetchData}
        onAction={handleTableAction}
        renderText={handleRenderText}
        renderAction={handleRenderAction}
        title={t("cards.title")}
      />
    </section>
  );
};

export default CardsPage;
